import { Loading } from "@bosonprotocol/react-kit";
import { ethers } from "ethers";
import { Form, Formik } from "formik";
import { useState } from "react";
import { toast } from "react-hot-toast";
import * as Yup from "yup";

import { useCreateSeller } from "../../../../../lib/hooks/sellers/meta-seller/useCreateSeller";
import { websitePattern } from "../../../../../lib/regex/url";
import SimpleError from "../../../../error/SimpleError";
import { FileProps } from "../../../../form/Upload/WithUploadToIpfs";
import { MAX_LOGO_SIZE } from "../../../../image/const";
import { validationOfRequiredImage } from "../../../../image/validationUtils";
import SuccessToast from "../../../../toasts/common/SuccessToast";
import BosonButton from "../../../../ui/BosonButton";
import Grid from "../../../../ui/Grid";
import Typography from "../../../../ui/Typography";
import { useModal } from "../../../useModal";
import Step1 from "./Step1";
import Step2 from "./Step2";

type Props = {
  authId: string;
};

type CreateSellerForm = {
  step1: {
    name: string;
    description: string;
    profilePicture: FileProps[];
    externalId: string;
    webhookURL: string;
    externalLink: string;
    authId: string;
  };
  step2: {
    secondaryRoyalties: number;
    addressForRoyaltyPayment: string;
  };
};

export default function CreateSellerModal({ authId }: Props) {
  const { hideModal } = useModal();
  const { mutateAsync } = useCreateSeller();
  const [hasError, setError] = useState<boolean>(false);
  const [step, setStep] = useState<1 | 2>(1);
  return (
    <Formik<CreateSellerForm>
      initialValues={{
        step1: {
          name: "",
          description: "",
          profilePicture: [],
          externalId: "",
          webhookURL: "",
          externalLink: "",
          authId
        },
        step2: {
          secondaryRoyalties: 0,
          addressForRoyaltyPayment: ""
        }
      }}
      onSubmit={async ({ step1, step2 }) => {
        try {
          setError(false);
          await mutateAsync({
            name: step1.name,
            description: step1.description,
            profileImageUrl: step1.profilePicture[0].src,
            externalId: step1.externalId,
            webhookURL: step1.webhookURL,
            externalLink: step1.externalLink,
            authId: step1.authId,
            secondaryRoyalties: step2.secondaryRoyalties,
            addressForRoyaltyPayment: step2.addressForRoyaltyPayment
          });

          toast((t) => (
            <SuccessToast t={t}>
              <Typography>Seller has been created</Typography>
            </SuccessToast>
          ));
          hideModal();
        } catch (error) {
          setError(true);
          console.error(error);
        }
      }}
      validationSchema={Yup.object({
        step1: Yup.object({
          profilePicture: validationOfRequiredImage(MAX_LOGO_SIZE),
          name: Yup.string().required("Name is a required field"),
          description: Yup.string().required("Description is a required field"),
          externalId: Yup.string().required("External ID is a required field"),
          webhookURL: Yup.string()
            .required("WebhookURL is a required field")
            .matches(new RegExp(websitePattern), "This is not a URL"),
          externalLink: Yup.string()
            .required("External link is a required field")
            .matches(new RegExp(websitePattern), "This is not a URL"),
          authId: Yup.string().required("Auth ID is a required field")
        }),
        step2: Yup.object({
          secondaryRoyalties: Yup.number()
            .min(0, "Must be greater than or equal to 0%")
            .max(10, "Must be less than or equal to 10%"),
          addressForRoyaltyPayment: Yup.string()
            .trim()
            .test("FORMAT", "Must be an address", (value) =>
              value ? ethers.utils.isAddress(value) : true
            )
        })
      })}
    >
      {({ isSubmitting, setFieldTouched, errors }) => {
        return (
          <Form>
            <div>
              {step === 1 ? (
                <Step1 prefix="step1." />
              ) : (
                <Step2 prefix="step2." />
              )}
              {hasError && <SimpleError />}
              <div style={{ marginTop: "1rem" }}>
                {step === 1 ? (
                  <BosonButton
                    type="button"
                    onClick={() => {
                      setFieldTouched("step1.profilePicture", true);
                      setFieldTouched("step1.name", true);
                      setFieldTouched("step1.description", true);
                      setFieldTouched("step1.externalId", true);
                      setFieldTouched("step1.webhookURL", true);
                      setFieldTouched("step1.externalLink", true);
                      if (!("step1" in errors)) {
                        setStep(2);
                      }
                    }}
                  >
                    Next
                  </BosonButton>
                ) : (
                  <Grid flexDirection="row" justifyContent="space-between">
                    <BosonButton
                      type="button"
                      onClick={() => setStep(1)}
                      variant="accentInverted"
                    >
                      Back
                    </BosonButton>
                    <BosonButton type="submit" disabled={isSubmitting}>
                      {isSubmitting ? (
                        <Grid gap="1rem">
                          Creating
                          <Loading />
                        </Grid>
                      ) : (
                        <>Create</>
                      )}
                    </BosonButton>
                  </Grid>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
