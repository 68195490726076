import { useApigee } from "../../../../../components/apigee/context";
import { CONFIG } from "../../../../config/config";

export function useCredentials() {
  const apigeeApps = useApigee();
  const anyApigeeApp = apigeeApps.find(
    (apigeeApp) => !!apigeeApp.owner && !!apigeeApp.credentials.length
  );
  const anyApiKey = anyApigeeApp?.credentials?.[0];
  const partnerId = anyApigeeApp?.owner;
  const sendHeaders = CONFIG.metaSellerUrl.startsWith("http://localhost");
  return {
    anyApiKey,
    partnerId,
    sendHeaders
  };
}
