import styled from "styled-components";

import Typography from "../../../components/ui/Typography";
import { colors } from "../../../lib/styles/colors";

const DashboardMain = styled.main`
  padding: 1.375rem 2.5rem 2.75rem 2.5rem;
  background: ${colors.lightGrey};
  min-height: calc(100vh - 5.5rem);
  overflow-x: auto;
`;
const DashboardTitle = styled(Typography)`
  margin: 0 0 1.25rem 0;
`;
const DashboardInner = styled.div`
  width: 1500px;
  background: ${colors.white};
  padding: 1rem;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 2%), 0px 0px 10px 0px rgb(0 0 0 / 2%),
    0px 0px 15px 0px rgb(0 0 0 / 5%);
`;
export const LoadingWrapper = styled.div`
  text-align: center;
`;

interface Props {
  children: React.ReactNode;
  label: string;
  withoutWrapper?: boolean;
}

export default function DashboardWrapper({
  label,
  children,
  withoutWrapper = false
}: Props) {
  return (
    <DashboardMain>
      <DashboardTitle tag="h3">{label}</DashboardTitle>
      {withoutWrapper ? children : <DashboardInner>{children}</DashboardInner>}
    </DashboardMain>
  );
}
