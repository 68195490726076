import styled from "styled-components";

import { colors } from "../../../../../lib/styles/colors";
import { Input } from "../../../../form";
import Upload from "../../../../form/Upload/Upload";
import Grid from "../../../../ui/Grid";
import Typography from "../../../../ui/Typography";

const SubTitle = styled(Typography)`
  margin: 0;
  font-weight: 400;
  font-size: 0.75rem;
  color: ${colors.darkGrey};
`;
const flexBasis = "130px";
export default function Step1({ prefix }: { prefix: string }) {
  return (
    <Grid flexDirection="column" alignItems="flex-end" gap="1rem">
      <Typography>
        Here you can create a seller, who will be assigned a random wallet. All
        roles associated with the seller will be set to this same wallet. You
        must set a unique external ID which can be used to help you identify the
        seller in your own system.
      </Typography>
      <Grid>
        <Grid flexDirection="column" alignItems="flex-start">
          <Typography>Profile picture:</Typography>
          <SubTitle>Upload a profile image with a max. size of 300Kb</SubTitle>
        </Grid>
        <Grid flexDirection="row-reverse">
          <Upload
            name={prefix + "profilePicture"}
            multiple={false}
            withUpload
          />
        </Grid>
      </Grid>
      <Grid>
        <Typography flexShrink="0" flexBasis={flexBasis}>
          Name:
        </Typography>
        <Grid flexDirection="column" alignItems="flex-start">
          <Input name={prefix + "name"} placeholder="Type here your name" />
        </Grid>
      </Grid>
      <Grid>
        <Typography flexShrink="0" flexBasis={flexBasis}>
          Description:
        </Typography>
        <Grid flexDirection="column" alignItems="flex-start">
          <Input
            name={prefix + "description"}
            placeholder="Type here your description"
          />
        </Grid>
      </Grid>
      <Grid>
        <Typography flexShrink="0" flexBasis={flexBasis}>
          External ID:
        </Typography>
        <Grid flexDirection="column" alignItems="flex-start">
          <Input
            name={prefix + "externalId"}
            placeholder="Type here your external ID"
          />
        </Grid>
      </Grid>

      <Grid>
        <Typography flexShrink="0" flexBasis={flexBasis}>
          Webhook URL:
        </Typography>
        <Grid flexDirection="column" alignItems="flex-start">
          <Input
            name={prefix + "webhookURL"}
            placeholder="Type here your webhook URL"
          />
        </Grid>
      </Grid>

      <Grid>
        <Typography flexShrink="0" flexBasis={flexBasis}>
          External link:
        </Typography>
        <Grid flexDirection="column" alignItems="flex-start">
          <Input
            name={prefix + "externalLink"}
            placeholder="Type here your external link"
          />
        </Grid>
      </Grid>

      <Grid>
        <Typography flexShrink="0" flexBasis={flexBasis}>
          Auth ID:
        </Typography>
        <Grid flexDirection="column" alignItems="flex-start">
          <Input name={prefix + "authId"} disabled />
        </Grid>
      </Grid>
    </Grid>
  );
}
