import { ReactNode, useEffect, useState } from "react";

import { CONFIG } from "../../lib/config/config";
import { ApigeeContext } from "./context";
import { ApigeeApps, apigeeAppsSchema } from "./validation";

export const ApigeeProvider = ({ children }: { children: ReactNode }) => {
  const [apigeeApps, setApigeeApps] = useState<ApigeeApps>([]);
  useEffect(() => {
    if (apigeeApps.length || window.top === window) {
      return;
    }
    const intervalId = setInterval(() => {
      window.top?.parent?.postMessage({ ready: true }, CONFIG.apigee.url);
    });
    return () => clearInterval(intervalId);
  }, [apigeeApps]);

  useEffect(() => {
    const listener = async function (
      event: MessageEvent<{ apigeeApps: ApigeeApps }>
    ) {
      let data: ApigeeApps;
      if (event.origin !== CONFIG.apigee.url) {
        if (CONFIG.mockApigee) {
          data = CONFIG.mockApigee as ApigeeApps;
        } else {
          return;
        }
      } else {
        data = event.data.apigeeApps;
      }
      const apigeeAppsValidated = await apigeeAppsSchema.validate(data);

      setApigeeApps(apigeeAppsValidated);
    };
    window.addEventListener("message", listener, false);
    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);
  return (
    <ApigeeContext.Provider value={apigeeApps}>
      {children}
    </ApigeeContext.Provider>
  );
};
