import { Suspense } from "react";

import App from "../app";
import Loading from "../ui/Loading";
import { baseAppProps, IRoutes } from "./routes";

export default function SuspenseRoute({
  app,
  component: Component,
  componentProps
}: IRoutes) {
  return (
    <Suspense
      fallback={
        <App {...baseAppProps}>
          <Loading />
        </App>
      }
    >
      <App {...app}>
        <Component {...componentProps} />
      </App>
    </Suspense>
  );
}
