import { ButtonSize } from "@bosonprotocol/react-kit";
import { AuthTokenType } from "@bosonprotocol/react-kit";
/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);
import {
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  CopySimple
} from "phosphor-react";
import { useMemo } from "react";
import {
  CellProps,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import styled from "styled-components";

import { useApigee } from "../../../../components/apigee/context";
import { useModal } from "../../../../components/modal/useModal";
import Tooltip from "../../../../components/tooltip/Tooltip";
import BosonButton from "../../../../components/ui/BosonButton";
import Button from "../../../../components/ui/Button";
import Grid from "../../../../components/ui/Grid";
import Loading from "../../../../components/ui/Loading";
import Typography from "../../../../components/ui/Typography";
import { formatAddress } from "../../../../lib/address/address";
import copyToClipboard from "../../../../lib/clipboard/copyToClipboard";
import { useGetSellers } from "../../../../lib/hooks/sellers/meta-seller/useGetSellers";
import { colors } from "../../../../lib/styles/colors";
import { ApigeeMetaSeller } from "../../../../lib/types/meta-seller";
import PaginationPages from "../common/PaginationPages";
import { WithSellerDataProps } from "../common/WithSellerData";
import { DashboardInsideProps } from "../layout/DashboardInside";

const authTokenTypesValueToKey = Object.entries(AuthTokenType).reduce(
  (previous, [key, value]) => {
    previous[value] = key as keyof typeof AuthTokenType;
    return previous;
  },
  {} as Record<
    typeof AuthTokenType[keyof typeof AuthTokenType],
    keyof typeof AuthTokenType
  >
);

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th {
    font-weight: 600;
    color: ${colors.darkGrey};
    :not([data-sortable]) {
      cursor: default !important;
    }
    [data-sortable] {
      cursor: pointer !important;
    }
  }
  td {
    font-weight: 400;
    color: ${colors.black};
  }
  th,
  td {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-size: 0.75rem;
    line-height: 1.5;
  }
  thead {
    tr {
      th {
        border-bottom: 2px solid ${colors.border};
        text-align: left;
        padding: 0.5rem;
        &:first-child {
          padding-left: 0.5rem;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }
  tbody {
    tr {
      :hover {
        td {
          background-color: ${colors.darkGrey}08;
          cursor: pointer;
        }
      }
      &:not(:last-child) {
        td {
          border-bottom: 1px solid ${colors.border};
        }
      }
      td {
        text-align: left;
        padding: 0.5rem;
        &:first-child {
        }
        &:last-child {
          text-align: right;
          > button {
            display: inline-block;
          }
        }
      }
    }
  }
`;

const HeaderSorter = styled.span`
  margin-left: 0.5rem;
`;
const Pagination = styled.div`
  width: 100%;
  padding-top: 1rem;
  border-top: 2px solid ${colors.border};

  select {
    padding: 0.5rem;
    border: 1px solid ${colors.border};
    background: ${colors.white};
    margin: 0 1rem;
  }
`;
const Span = styled.span`
  font-size: 0.75rem;
  color: ${colors.darkGrey};
  &:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

export default function DashboardSellers(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: DashboardInsideProps & WithSellerDataProps
) {
  const { showModal } = useModal();

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id"
      } as const,
      {
        Header: "Name",
        accessor: "apigeeApp.name",
        Cell: (props: CellProps<any>) => {
          return <>{props.value}</>;
        }
      } as const,
      {
        Header: "Admin",
        accessor: "admin",
        Cell: (props: CellProps<any>) => {
          return (
            <Tooltip content={props.value} maxWidth="none" wrap={false}>
              <>{formatAddress(props.value)}</>
            </Tooltip>
          );
        }
      } as const,
      {
        Header: "Clerk",
        accessor: "clerk",
        Cell: (props: CellProps<any>) => {
          return (
            <Tooltip content={props.value} maxWidth="none" wrap={false}>
              <>{formatAddress(props.value)}</>
            </Tooltip>
          );
        }
      } as const,
      {
        Header: "Operator",
        accessor: "operator",
        Cell: (props: CellProps<any>) => {
          return (
            <Tooltip content={props.value} maxWidth="none" wrap={false}>
              <>{formatAddress(props.value)}</>
            </Tooltip>
          );
        }
      } as const,
      {
        Header: "Treasury",
        accessor: "treasury",
        Cell: (props: CellProps<any>) => {
          return (
            <Tooltip content={props.value} maxWidth="none" wrap={false}>
              <>{formatAddress(props.value)}</>
            </Tooltip>
          );
        }
      } as const,
      {
        Header: "External ID",
        accessor: "externalId"
      } as const,
      {
        Header: "AuthTokenId",
        accessor: "authTokenId"
      } as const,
      {
        Header: "AuthTokenType",
        accessor: "authTokenType",
        Cell: (props: CellProps<any>) => {
          return (
            <Tooltip content={props.value + ""} maxWidth="none" wrap={false}>
              <>{authTokenTypesValueToKey[props.value]}</>
            </Tooltip>
          );
        }
      } as const,
      {
        Header: "Active",
        accessor: "active",
        Cell: (props: CellProps<any>) => {
          return <>{props.value !== undefined ? "" + props.value : ""}</>;
        }
      } as const,
      {
        Header: "Webhook URL",
        accessor: "webhookURL",
        Cell: (props: CellProps<any>) => {
          return <>{props.value ?? "" + ""}</>;
        }
      } as const,
      {
        Header: "Action",
        Cell: (props: CellProps<any>) => {
          const seller = props.row.values as ApigeeMetaSeller;
          const exists = !!seller.id;
          const apigeeWrapper = props.row.original as Pick<
            ApigeeMetaSeller,
            "apigeeApp"
          >;
          const {
            credentials: [{ consumerKey }]
          } = apigeeWrapper.apigeeApp;
          const authId = apigeeWrapper.apigeeApp.id;
          const CTA = exists ? (
            <BosonButton
              variant="accentInverted"
              size={ButtonSize.Small}
              onClick={() => {
                showModal("UPDATE_SELLER", {
                  title: "Update seller",
                  sellerId: seller.id,
                  authId
                });
              }}
              disabled={seller.operator !== seller.admin}
            >
              Update seller
            </BosonButton>
          ) : (
            <BosonButton
              variant="accentInverted"
              size={ButtonSize.Small}
              onClick={() => {
                showModal(
                  "CREATE_SELLER",
                  {
                    title: "Create seller",
                    onClose: () => refetch(),
                    authId
                  },
                  "auto",
                  undefined,
                  {
                    s: "600px"
                  }
                );
              }}
            >
              Create seller
            </BosonButton>
          );
          return (
            <Grid flexDirection="row" gap="1rem">
              {consumerKey && (
                <Button
                  theme="blank"
                  onClick={() =>
                    copyToClipboard(consumerKey).catch(console.error)
                  }
                >
                  <Grid flexDirection="row">
                    <CopySimple size={15} />
                    <Typography $fontSize="0.8rem">Copy Api Key</Typography>
                  </Grid>
                </Button>
              )}
              {CTA}
            </Grid>
          );
        }
      } as const
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { data, isLoading, isError, refetch } = useGetSellers();

  const apigeeApps = useApigee();

  const sellers = useMemo(() => {
    return isError
      ? []
      : apigeeApps.map((app) => {
          const { id } = app;
          const seller = data?.find((seller) => seller.authId === id);
          return {
            ...seller,
            apigeeApp: app
          } as ApigeeMetaSeller;
        }) || [];
  }, [data, apigeeApps, isError]);

  const tableProps = useTable(
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns,
      data: sellers,
      initialState: { pageIndex: 0 }
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageCount,
    state: { pageIndex, pageSize }
  } = tableProps;

  const paginate = useMemo(() => {
    return Array.from(Array(pageCount).keys()).slice(
      pageIndex < 1 ? 0 : pageIndex - 1,
      pageIndex < 1 ? 3 : pageIndex + 2
    );
  }, [pageCount, pageIndex]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <p>There has been an error</p>;
  }

  return (
    <Grid flexDirection="column" alignItems="flex-end">
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, key) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={`seller_table_thead_tr_${key}`}
            >
              {headerGroup.headers.map((column: any, i) => {
                return (
                  <th
                    data-sortable={column.disableSortBy}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={`seller_table_thead_th_${i}`}
                  >
                    {column.render("Header")}
                    {i >= 0 && !column.disableSortBy && (
                      <HeaderSorter>
                        {column?.isSorted ? (
                          column?.isSortedDesc ? (
                            <CaretDown size={14} />
                          ) : (
                            <CaretUp size={14} />
                          )
                        ) : (
                          ""
                        )}
                      </HeaderSorter>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(page.length > 0 &&
            page.map((row: any, id: any) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={`seller_table_finances_tbody_tr_${id}`}
                >
                  {row.cells.map((cell: any, key: any) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={`seller_table_finances_tbody_td_${id}-${key}`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })) || (
            <tr>
              <td colSpan={columns.length}>
                <Typography
                  tag="h6"
                  justifyContent="center"
                  padding="1rem 0"
                  margin="0"
                >
                  No data to display
                </Typography>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Pagination>
        <Grid>
          <Grid justifyContent="flex-start" gap="1rem">
            <Span>
              Show
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 25, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
              per page
            </Span>
            <PaginationPages
              pageIndex={pageIndex + 1}
              pageSize={pageSize}
              allItems={rows.length}
            />
          </Grid>
          {pageCount > 1 && (
            <Grid justifyContent="flex-end" gap="1rem">
              <Button
                size="small"
                theme="blank"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <CaretLeft size={16} />
              </Button>
              {paginate.map((pageNumber: number) => (
                <Button
                  key={`page_btn_${pageNumber}`}
                  size="small"
                  theme="blank"
                  style={{
                    color:
                      pageNumber === pageIndex
                        ? colors.secondary
                        : colors.black,
                    background:
                      pageNumber === pageIndex
                        ? colors.lightGrey
                        : "transparent"
                  }}
                  onClick={() => gotoPage(pageNumber)}
                >
                  {pageNumber + 1}
                </Button>
              ))}
              <Button
                size="small"
                theme="blank"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <CaretRight size={16} />
              </Button>
            </Grid>
          )}
        </Grid>
      </Pagination>
    </Grid>
  );
}
