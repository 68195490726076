import { useMutation } from "react-query";

import { MetaSeller } from "../../../types/meta-seller";
import { fetchMetaSeller } from "./utils/fetchMetaSeller";
import { useCredentials } from "./utils/useCredentials";

export interface Variables {
  name: string;
  description: string;
  profileImageUrl: string;
  externalId: string;
  webhookURL: string;
  externalLink: string;
  authId: string;
  secondaryRoyalties: number;
  addressForRoyaltyPayment: string;
}

export function useCreateSeller(
  options: Parameters<typeof useMutation>[2] = {}
) {
  const { anyApiKey, partnerId, sendHeaders } = useCredentials();
  return useMutation<MetaSeller, unknown, Variables>(
    ["create-seller"],
    async (variables) => {
      return await fetchMetaSeller<MetaSeller>(
        `/partner/seller/?apikey=${anyApiKey?.consumerKey}`,
        {
          method: "POST",
          headers: {
            ...(sendHeaders && { "x-developer-id": partnerId })
          },
          data: {
            name: variables.name,
            description: variables.description,
            profileImageUrl: variables.profileImageUrl,
            externalId: variables.externalId,
            webhookURL: variables.webhookURL,
            externalLink: variables.externalLink,
            authId: variables.authId,
            secondaryRoyalties: variables.secondaryRoyalties,
            addressForRoyaltyPayment: variables.addressForRoyaltyPayment
          }
        }
      );
    },
    {
      ...options
    }
  );
}
