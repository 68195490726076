import { Button } from "@bosonprotocol/react-kit";
import { useCallback, useState } from "react";
import styled from "styled-components";

import {
  getItemFromStorage,
  saveItemInStorage
} from "../../lib/hooks/storage/useLocalStorage";
import { colors } from "../../lib/styles/colors";
import { zIndex } from "../../lib/styles/zIndex";
import Grid from "../ui/Grid";

const StyledCookie = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  > div {
    position: relative;
    z-index: ${zIndex.Cookie};
    background-color: ${colors.white};
    color: ${colors.black};
    padding: 2rem;
  }
  &:before {
    content: "";
    position: absolute;
    top: -100vh;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${zIndex.Cookie - 1};
    background-color: ${colors.black}80;
  }

  button {
    color: ${colors.white};
  }
`;

export default function CookieBanner() {
  const [show, setShow] = useState<boolean>(
    getItemFromStorage("showCookies", true)
  );

  const handleOkey = useCallback(() => {
    setShow(false);
    saveItemInStorage("showCookies", false);
  }, [setShow]);

  if (!show) {
    return null;
  }

  return (
    <StyledCookie>
      <Grid gap="2rem">
        {/* 
          // TODO: add privacy policy and terms and conditions
        */}
        Cookie banner
        <Button variant="accentFill" onClick={handleOkey}>
          Okay
        </Button>
      </Grid>
    </StyledCookie>
  );
}
