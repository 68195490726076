import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

import { subgraph } from "@bosonprotocol/react-kit";
import React, { useMemo } from "react";
import styled from "styled-components";

import Loading from "../../../../components/ui/Loading";
import { CONFIG } from "../../../../lib/config/config";
import { useConvertionRate } from "../../../../lib/hooks/convertion-rate/useConvertionRate";
import {
  ExchangeTokensProps,
  useExchangeTokens
} from "../../../../lib/hooks/exchange-tokens/useExchangeTokens";
import useFunds, { FundsProps } from "../../../../lib/hooks/finances/useFunds";
import {
  SellerProps,
  useSellerDeposit
} from "../../../../lib/hooks/finances/useSellerDeposit";
import {
  SellerRolesProps,
  useSellerRoles
} from "../../../../lib/hooks/roles/useSellerRoles";
import { DashboardInsideProps } from "../layout/DashboardInside";
import useOffersBacked from "./useOffersBacked";

export const Wrapper = styled.div`
  text-align: center;
`;

interface QueryProps {
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
}

interface ExchangesTokensProps extends QueryProps {
  data: ExchangeTokensProps[] | undefined;
}
interface SellerDepositProps extends QueryProps {
  data: SellerProps | undefined;
}
export interface BackedProps {
  token: string;
  value: number | null;
}
export interface OffersBackedProps {
  offersBacked: BackedProps[];
  calcOffersBacked: {
    [x: string]: string;
  };
  sellerLockedFunds: {
    [x: string]: string;
  };
  threshold: number;
  displayWarning: boolean;
  offersBackedFn: (fund: subgraph.FundsEntityFieldsFragment) => number | null;
}

export interface WithSellerDataProps {
  funds: FundsProps;
  exchangesTokens: ExchangesTokensProps;
  sellerDeposit: SellerDepositProps;
  offersBacked: OffersBackedProps;
  sellerRoles: SellerRolesProps;
}
export function WithSellerData(
  WrappedComponent: React.ComponentType<
    DashboardInsideProps & WithSellerDataProps
  >
) {
  const ComponentWithSellerData = (props: DashboardInsideProps) => {
    const sellerId = CONFIG.mockSellerId || props.sellerId;
    const sellerRoles = useSellerRoles(sellerId || "");
    const {
      store: { tokens }
    } = useConvertionRate();

    const exchangesTokens = useExchangeTokens(
      {
        sellerId: sellerId || ""
      },
      {
        enabled: !!sellerId
      }
    );
    const sellerDeposit = useSellerDeposit(
      {
        sellerId: sellerId || ""
      },
      { enabled: !!sellerId }
    );
    const funds = useFunds(sellerId || "", tokens);
    const newProps = useMemo(
      () => ({
        sellerId,
        exchangesTokens,
        sellerDeposit,
        funds,
        sellerRoles
      }),
      [sellerId, exchangesTokens, sellerDeposit, funds, sellerRoles]
    );

    const offersBacked = useOffersBacked({ ...newProps });

    if (exchangesTokens.isLoading || sellerDeposit.isLoading) {
      return (
        <Wrapper>
          <Loading />
        </Wrapper>
      );
    }
    return (
      <WrappedComponent {...props} {...newProps} offersBacked={offersBacked} />
    );
  };
  return ComponentWithSellerData;
}
