import { CONFIG } from "../../../../config/config";

interface WithData {
  method: "PUT" | "POST";
  data?: unknown;
}

interface WithoutData {
  method: "GET" | "DELETE";
}

type Props = (WithData | WithoutData) & {
  headers?: {
    "x-developer-id"?: string;
    "x-developer-app-id"?: string;
  };
};

export async function fetchMetaSeller<T>(
  url: `/partner/${string}` | `/seller/${string}`,
  { method, headers, ...rest }: Props
): Promise<T> {
  try {
    const data = "data" in rest ? rest.data : null;
    const response = await fetch(`${CONFIG.metaSellerUrl}${url}`, {
      method,
      headers: {
        ...headers,
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-env-name": CONFIG.envName
      },
      ...(data !== null && { body: JSON.stringify(data) })
    });
    const json = await response.json();
    if (!response.ok) {
      throw json;
    }
    return json as T;
  } catch (err) {
    console.error(err);
    throw err;
  }
}
