import { useField } from "formik";

import { colors } from "../../../../../lib/styles/colors";
import { Error, FormField, Input } from "../../../../form";
import Tooltip from "../../../../tooltip/Tooltip";
import Grid from "../../../../ui/Grid";
import Typography from "../../../../ui/Typography";

export default function Step2({ prefix }: { prefix: string }) {
  const [
    fieldSecondaryRoyalties,
    metaSecondaryRoyalties,
    helpersSecondaryRoyalties
  ] = useField<number>(prefix + "secondaryRoyalties");

  const [, , helpersAddressForRoyaltyPayment] = useField<string>(
    prefix + "addressForRoyaltyPayment"
  );
  return (
    <Grid flexDirection="column" gap="0.5rem" alignItems="flex-start">
      <Typography fontWeight="600" $fontSize="2rem" lineHeight="2.4rem">
        Boson Account
      </Typography>
      <Typography>
        You are creating a seller account in Boson Protocol. Input the relevant
        information relating to your secondary royalty
      </Typography>
      <FormField
        title="Secondary Royalties"
        subTitle="Boson Protocol implements EIP-2981 which enables secondary royalties across NFT marketplaces."
        required
      >
        <Grid flexDirection="column" alignItems="flex-start">
          <Grid>
            <Grid
              style={{
                background: colors.lightGrey,
                border: `1px solid ${colors.border}`
              }}
              gap="0.5rem"
              justifyContent="space-between"
            >
              <Grid flexDirection="column">
                <Input
                  name={prefix + "secondaryRoyalties"}
                  placeholder=""
                  style={{
                    border: "none",
                    textAlign: "right"
                  }}
                  hideError
                  type="number"
                  step="0.01"
                  onChange={(event) => {
                    if (!event.target.valueAsNumber) {
                      helpersAddressForRoyaltyPayment.setValue("");
                    }
                    helpersSecondaryRoyalties.setValue(
                      event.target.valueAsNumber
                    );
                  }}
                />
              </Grid>
              <div style={{ padding: "1rem" }}>%</div>
            </Grid>
            <Tooltip content="Royalties are limited to 10%" size={16} />
          </Grid>
          <Error
            display={!!metaSecondaryRoyalties.error}
            message={metaSecondaryRoyalties.error}
          />
        </Grid>
      </FormField>
      <FormField
        title="Address for Royalty payment"
        subTitle="This address will receive royalty payments"
      >
        <Input
          name={prefix + "addressForRoyaltyPayment"}
          placeholder="f.e. 0x930fn3jr9dnW..."
          disabled={!fieldSecondaryRoyalties.value}
        />
      </FormField>
    </Grid>
  );
}
