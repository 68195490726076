import React from "react";
import { RouteProps } from "react-router";

import { BosonRoutes } from "../../lib/routing/routes";
import Dashboard from "../../pages/dashboard/Dashboard";

export const baseAppProps = {
  withLayout: true,
  fluidHeader: false,
  withBanner: false
};
const base = {
  component: null,
  index: false,
  app: baseAppProps,
  role: []
};

export type IRoutes = RouteProps & {
  component:
    | React.ComponentType<any> // eslint-disable-line
    | React.LazyExoticComponent<React.ComponentType<any>>; // eslint-disable-line
  role: Array<string | null>;
  componentProps?: {
    [key: string]: string;
  };
  app?: {
    withLayout?: boolean;
    fluidHeader?: boolean;
    withBanner?: boolean;
  };
};
export default [
  {
    ...base,
    index: true,
    path: BosonRoutes.Root,
    component: Dashboard,
    app: {
      ...base.app,
      withBanner: true,
      withHeader: true
    }
  },
  {
    ...base,
    index: true,
    path: `${BosonRoutes.DashboardInside}`,
    component: Dashboard,
    app: {
      ...base.app,
      withBanner: true,
      withHeader: true
    }
  },
  {
    ...base,
    index: true,
    path: `${BosonRoutes.Dashboard}`,
    component: Dashboard,
    app: {
      ...base.app,
      withBanner: true,
      withHeader: true
    }
  }
];
