import { IconContext } from "phosphor-react";
import styled, { ThemeProvider } from "styled-components";

import Layout from "../../components/layout/Layout";
import ModalProvider from "../../components/modal/ModalProvider";
import GlobalStyle from "../../lib/styles/GlobalStyle";
import theme from "../../lib/styles/theme";
import CookieBanner from "../cookie/CookieBanner";
import Header from "../header/Header";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

interface Props {
  children: JSX.Element;
  fluidHeader?: boolean;
  withHeader?: boolean;
}
export default function App({
  fluidHeader = false,
  withHeader,
  children
}: Props) {
  return (
    <ThemeProvider theme={theme}>
      <IconContext.Provider
        value={{
          size: 32,
          weight: "bold"
        }}
      >
        <ModalProvider>
          <>
            <Container>
              <GlobalStyle />
              {withHeader && <Header fluidHeader={fluidHeader} />}
              <Layout>{children}</Layout>
            </Container>
            <CookieBanner />
          </>
        </ModalProvider>
      </IconContext.Provider>
    </ThemeProvider>
  );
}
