import { hooks } from "@bosonprotocol/react-kit";

import { CONFIG } from "../../lib/config/config";

export function useIpfsStorage() {
  const storage = hooks.useIpfsMetadataStorage(
    CONFIG.envName,
    CONFIG.ipfsMetadataStorageUrl,
    CONFIG.ipfsMetadataStorageHeaders
  );
  return storage;
}
