import { HashRouter, Route } from "react-router-dom";

import ScrollToTop from "../scroll/Scroll";
import SentryProvider from "../sentry/SentryProvider";
import routes, { IRoutes } from "./routes";
import SuspenseRoute from "./SuspenseRoute";

export default function AppRouter() {
  return (
    <HashRouter>
      <ScrollToTop />
      <SentryProvider>
        <>
          {routes.map((route: IRoutes) => (
            <Route
              key={`route_${route.path}`}
              {...route}
              element={<SuspenseRoute {...route} />}
            />
          ))}
        </>
      </SentryProvider>
    </HashRouter>
  );
}
