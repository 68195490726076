import { useQuery } from "react-query";

import { MetaSeller } from "../../../types/meta-seller";
import { fetchMetaSeller } from "./utils/fetchMetaSeller";
import { useCredentials } from "./utils/useCredentials";

export function useGetSellers(
  options: Parameters<typeof useQuery<MetaSeller[]>>[2] = {}
) {
  const { anyApiKey, partnerId, sendHeaders } = useCredentials();
  return useQuery<MetaSeller[]>(
    ["get-sellers"],
    async () => {
      const result =
        (await fetchMetaSeller<MetaSeller[]>(
          `/partner/seller/?apikey=${anyApiKey?.consumerKey}`,
          {
            method: "GET",
            headers: {
              ...(sendHeaders && { "x-developer-id": partnerId })
            }
          }
        )) || [];
      return result;
    },
    {
      ...options
    }
  );
}
