import styled from "styled-components";
import { useAccount } from "wagmi";

import Loading from "../../components/ui/Loading";
import { useSellers } from "../../lib/hooks/sellers/useSellers";
import { colors } from "../../lib/styles/colors";
import {
  WithSellerData,
  WithSellerDataProps
} from "./components/common/WithSellerData";
import DashboardAside from "./components/layout/DashboardAside";
import DashboardInside, {
  DashboardInsideProps
} from "./components/layout/DashboardInside";

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 14.3em 1fr;
  gap: 0;
  min-height: calc(100vh - 30.25rem);
  margin: 0 -1rem;
  font-family: "Plus Jakarta Sans";
  color: ${colors.black};
`;

function InnerDashboard(props: DashboardInsideProps & WithSellerDataProps) {
  return (
    <GridWrapper>
      <DashboardAside />
      <DashboardInside {...props} />
    </GridWrapper>
  );
}

const DashboardWithData = WithSellerData(InnerDashboard);

export default function Dashboard() {
  const { address } = useAccount();
  const { data: admins, isLoading: isAdminsLoading } = useSellers(
    {
      admin: address
    },
    {
      enabled: !!address
    }
  );
  const { data: operators, isLoading: isOperatorsLoading } = useSellers(
    {
      operator: address
    },
    {
      enabled: !!address
    }
  );
  const { data: treasuries, isLoading: isTreasuriesLoading } = useSellers(
    {
      treasury: address
    },
    {
      enabled: !!address
    }
  );
  const { data: clerks, isLoading: isClerksLoading } = useSellers(
    {
      clerk: address
    },
    {
      enabled: !!address
    }
  );
  if (
    isAdminsLoading ||
    isOperatorsLoading ||
    isTreasuriesLoading ||
    isClerksLoading
  ) {
    return <Loading></Loading>;
  }
  if (!admins && !operators && !treasuries && !clerks) {
    return <p>Connect your wallet</p>;
  }
  const sellerId =
    admins?.[0]?.id ||
    operators?.[0]?.id ||
    treasuries?.[0]?.id ||
    clerks?.[0]?.id ||
    null;

  return <DashboardWithData sellerId={sellerId} />;
}
