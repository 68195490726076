import { Loading } from "@bosonprotocol/react-kit";
import { ethers } from "ethers";
import { Form, Formik } from "formik";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useAccount } from "wagmi";
import * as Yup from "yup";

import { useCoreSDK } from "../../../../lib/hooks/core-components/useCoreSdk";
import { useUpdateSeller } from "../../../../lib/hooks/sellers/meta-seller/useUpdateSeller";
import SimpleError from "../../../error/SimpleError";
import { Input } from "../../../form";
import SuccessToast from "../../../toasts/common/SuccessToast";
import BosonButton from "../../../ui/BosonButton";
import Grid from "../../../ui/Grid";
import Typography from "../../../ui/Typography";
import { useModal } from "../../useModal";

type UpdateSellerForm = {
  admin: string;
  clerk: string;
  treasury: string;
};

export default function UpdateSellerModal({
  sellerId,
  authId
}: {
  sellerId: string;
  authId: string;
}) {
  const { hideModal } = useModal();
  const { address } = useAccount();
  const { mutateAsync } = useUpdateSeller();
  const coreSDK = useCoreSDK();
  const [hasError, setError] = useState<boolean>(false);
  if (!address) {
    return <p>Please connect your address</p>;
  }
  return (
    <Formik<UpdateSellerForm>
      initialValues={{
        admin: address,
        clerk: address,
        treasury: address
      }}
      onSubmit={async (values) => {
        try {
          setError(false);
          const nonce = Date.now();
          const signedMetaTx = await coreSDK.signMetaTxOptInToSellerUpdate({
            nonce,
            optInToSellerUpdateArgs: {
              id: sellerId,
              fieldsToUpdate: {
                admin: true,
                clerk: true
              }
            }
          });
          await mutateAsync({
            seller: {
              id: sellerId,
              admin: values.admin,
              clerk: values.admin,
              treasury: values.admin,
              authId
            },
            optInToSellerUpdate: {
              functionName: signedMetaTx.functionName,
              functionSignature: signedMetaTx.functionSignature,
              r: signedMetaTx.r,
              s: signedMetaTx.s,
              v: signedMetaTx.v.toString(),
              nonce: nonce.toString()
            },
            signerAddress: values.admin
          });

          toast((t) => (
            <SuccessToast t={t}>
              <Typography>Seller has been updated</Typography>
            </SuccessToast>
          ));
          hideModal();
        } catch (error) {
          setError(true);
          console.error(error);
        }
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        admin: Yup.string()
          .required("Admin address is a required field")
          .test("FORMAT", "Must be an address", (value) =>
            value ? ethers.utils.isAddress(value) : true
          )
      })}
    >
      {({ isSubmitting, values }) => {
        return (
          <Form>
            <Grid flexDirection="column" alignItems="flex-end" gap="1rem">
              <p>
                Here you can update this seller to a different wallet address.
                The same wallet will be used for the admin, clerk and treasury
                roles as these are the accounts that you manage, hence only one
                input is enabled. You <strong>must</strong> be connected with
                the address you specify here.
              </p>
              <Grid alignItems="baseline">
                <Typography flexBasis="100px">Admin:</Typography>
                <Grid flexDirection="column" alignItems="flex-start">
                  <Input name="admin" placeholder="Admin address" />
                </Grid>
              </Grid>
              <Grid alignItems="baseline">
                <Typography flexBasis="100px">Clerk:</Typography>
                <Grid flexDirection="column" alignItems="flex-start">
                  <Input
                    name="clerk"
                    placeholder="Clerk address"
                    disabled
                    value={values.admin}
                  />
                </Grid>
              </Grid>
              <Grid alignItems="baseline">
                <Typography flexBasis="100px">Treasury:</Typography>
                <Grid flexDirection="column" alignItems="flex-start">
                  <Input
                    name="treasury"
                    placeholder="Treasury address"
                    disabled
                    value={values.admin}
                  />
                </Grid>
              </Grid>
              {hasError && <SimpleError />}
              <Grid justifyContent="flex-end">
                <BosonButton type="submit" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <Grid gap="1rem">
                      Updating
                      <Loading />
                    </Grid>
                  ) : (
                    <>Update</>
                  )}
                </BosonButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
