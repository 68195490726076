import * as Yup from "yup";

const apiProductSchema = Yup.object({
  status: Yup.string(),
  apiproduct: Yup.string()
});

const credentialsSchema = Yup.array(
  Yup.object({
    consumerKey: Yup.string().required(),
    consumerSecret: Yup.string().required(),
    issuedAt: Yup.number(),
    expiresAt: Yup.number(),
    status: Yup.string(),
    scopes: Yup.array(),
    apiProducts: Yup.array(apiProductSchema),
    attributes: Yup.array()
  })
).required();

export const apigeeAppsSchema = Yup.array(
  Yup.object({
    id: Yup.string().required(),
    name: Yup.string(),
    status: Yup.string(),
    owner: Yup.string().required(),
    ownerType: Yup.string(),
    description: Yup.string().nullable(),
    displayName: Yup.string(),
    created: Yup.string(),
    modified: Yup.string(),
    credentials: credentialsSchema,
    apiProducts: Yup.array(apiProductSchema),
    callbackUrl: Yup.string()
  })
).required();

export type ApigeeApps = Yup.InferType<typeof apigeeAppsSchema>;
