import { getDefaultConfig } from "@bosonprotocol/react-kit";

import { Token } from "../types/token";

type EnvironmentType = "local" | "testing" | "staging" | "production"; // TODO: export EnvironmentType in react-kit

const REACT_APP_ENV_NAME = process.env.REACT_APP_ENV_NAME;
export const config = getDefaultConfig(REACT_APP_ENV_NAME as EnvironmentType);

const REACT_APP_ENABLE_SENTRY_LOGGING =
  process.env.NODE_ENV === "development"
    ? stringToBoolean(process.env.REACT_APP_ENABLE_SENTRY_LOGGING)
    : ["local", "testing"].includes(config.envName);

export function getDefaultTokens(): Token[] {
  let tokens: Token[] = [];
  try {
    tokens = JSON.parse(
      process.env.REACT_APP_DEFAULT_TOKENS_LIST_TESTING ||
        process.env.REACT_APP_DEFAULT_TOKENS_LIST_STAGING ||
        process.env.REACT_APP_DEFAULT_TOKENS_LIST_PRODUCTION ||
        "[]"
    );
  } catch (e) {
    console.error(e);
  }
  return tokens;
}

function getMetaTxApiIds(protocolAddress: string) {
  const apiIds: Record<string, Record<string, string>> = {};
  try {
    const apiIdsInput = JSON.parse(
      process.env.REACT_APP_META_TX_API_IDS || "[]"
    );
    const method = "executeMetaTransaction"; // At the moment, both protocol and tokens have the same method
    const tokens = getDefaultTokens();
    Object.keys(apiIdsInput).forEach((key) => {
      if (key.toLowerCase() === "protocol") {
        apiIds[protocolAddress.toLowerCase()] = {};
        apiIds[protocolAddress.toLowerCase()][method] = apiIdsInput[key];
      } else {
        const token = tokens.find(
          (t: Token) => t.symbol.toLowerCase() === key.toLowerCase()
        );
        if (token) {
          apiIds[token.address.toLowerCase()] = {};
          apiIds[token.address.toLowerCase()][method] = apiIdsInput[key];
        } else {
          console.error(`Unable to resolve token with symbol ${key}`);
        }
      }
    });
  } catch (e) {
    console.error(e);
  }
  return apiIds;
}

export const CONFIG = {
  ...config,
  enableSentryLogging: REACT_APP_ENABLE_SENTRY_LOGGING,
  dateFormat: process.env.DATE_FORMAT || "YYYY/MM/DD",
  shortDateFormat: process.env.SHORT_DATE_FORMAT || "MMM DD, YYYY",
  fullDateFormat: process.env.FULL_DATE_FORMAT || "YYYY-MM-DDTHH:mm:ssZ[Z]",
  shortMonthWithDay: process.env.SHORT_MONTH_WITH_DAY_FORMAT || "MMM DD",
  defaultCurrency: {
    ticker: process.env.DEFAULT_CURRENCY || "USD",
    symbol: process.env.DEFAULT_CURRENCY_SYMBOL || "$"
  },
  envName: REACT_APP_ENV_NAME as EnvironmentType,
  sentryDSNUrl: "",
  ipfsGateway: "",
  ipfsImageGateway: "",
  metaSellerUrl: process.env.REACT_APP_META_SELLER_URL || "",
  ipfsMetadataStorageUrl:
    process.env.REACT_APP_IPFS_METADATA_URL || config.ipfsMetadataUrl,
  ipfsMetadataStorageHeaders: getIpfsMetadataStorageHeaders(
    process.env.REACT_APP_INFURA_IPFS_PROJECT_ID,
    process.env.REACT_APP_INFURA_IPFS_PROJECT_SECRET
  ),
  mockSellerId: "",
  defaultDisputeResolverId: "",
  apigee: {
    url: process.env.REACT_APP_APIGEE_URL as string
  },
  metaTx: {
    ...config.metaTx,
    apiKey: process.env.REACT_APP_META_TX_API_KEY,
    apiIds: getMetaTxApiIds(config.contracts.protocolDiamond)
  },
  mockApigee: process.env.REACT_APP_MOCK_APIGEE
    ? JSON.parse(process.env.REACT_APP_MOCK_APIGEE)
    : undefined
};

function stringToBoolean(value?: string) {
  if (typeof value === "string") {
    return ["1", "true"].includes(value);
  }

  return Boolean(value);
}

function getIpfsMetadataStorageHeaders(
  infuraProjectId?: string,
  infuraProjectSecret?: string
) {
  if (!infuraProjectId && !infuraProjectSecret) {
    return undefined;
  }

  return {
    authorization: `Basic ${Buffer.from(
      infuraProjectId + ":" + infuraProjectSecret
    ).toString("base64")}`
  };
}
