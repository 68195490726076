import { useMemo } from "react";
import { useParams } from "react-router";

import { UrlParameters } from "../../../../lib/routing/routes";
import { WithSellerDataProps } from "../common/WithSellerData";
import { dashboardPageTypes } from "../DashboardPages";
import DashboardWrapper from "../DashboardWrapper";

export interface DashboardInsideProps {
  sellerId: string | null;
}

export default function DashboardInside(
  props: DashboardInsideProps & WithSellerDataProps
) {
  const params = useParams();
  const { [UrlParameters.dashboardPage]: dashboardPage } = params;
  const { label, component, ...rest } = useMemo(
    () =>
      dashboardPageTypes[dashboardPage as keyof typeof dashboardPageTypes] ||
      dashboardPageTypes.sellers,
    [dashboardPage]
  );

  return (
    <DashboardWrapper label={label} {...rest}>
      {component(props)}
    </DashboardWrapper>
  );
}
