import { Bank, Users } from "phosphor-react";

import { WithSellerDataProps } from "./common/WithSellerData";
import SellerFinances from "./finances/SellerFinances";
import { DashboardInsideProps } from "./layout/DashboardInside";
import DashboardSellers from "./sellers/DashboardSellers";

export const dashboardPageTypes = {
  sellers: {
    url: "sellers",
    label: "Sellers",
    icon: Users,
    externalPath: null,
    component: (props: DashboardInsideProps & WithSellerDataProps) => (
      <DashboardSellers {...props} />
    ),
    withoutWrapper: false
  },
  finances: {
    url: "finances",
    label: "Finances",
    icon: Bank,
    externalPath: null,
    component: (props: DashboardInsideProps & WithSellerDataProps) => (
      <SellerFinances {...props} />
    ),
    withoutWrapper: false
  }
};
