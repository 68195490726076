export const UrlParameters = {
  dashboardPage: "dashboardPage"
};

export const BosonRoutes = {
  Root: "/",
  Dashboard: "/dashboard",
  DashboardInside: `/dashboard/:${UrlParameters.dashboardPage}`,
  Error404: "*"
} as const;
