import { forwardRef, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { useAccount } from "wagmi";

import logo from "../../../src/assets/logo.svg";
import { useBreakpoints } from "../../lib/hooks/style/useBreakpoints";
import { BosonRoutes } from "../../lib/routing/routes";
import { breakpoint } from "../../lib/styles/breakpoint";
import { colors } from "../../lib/styles/colors";
import { zIndex } from "../../lib/styles/zIndex";
import Layout from "../layout/Layout";
import Grid from "../ui/Grid";
import ConnectButton from "./ConnectButton";

const smallWidth = "180px";
const mediumWidth = "225px";
const sideMargin = "1rem";
const closedHeaderWidth = "75px";
const HEADER_HEIGHT = "5.4rem";
const Header = styled.header<{
  $navigationBarPosition: string;
  $isSideBarOpen: boolean;
}>`
  position: fixed;
  transition: width 300ms;

  ${({ $navigationBarPosition, $isSideBarOpen }) => {
    if (["left", "right"].includes($navigationBarPosition)) {
      return css`
        ${$isSideBarOpen
          ? css`
              width: ${smallWidth};
            `
          : css`
              width: ${closedHeaderWidth};
            `}
        top: 0;
        ${$navigationBarPosition === "left"
          ? css`
              left: 0;
              border-right: 2px solid ${colors.border};

              && {
                ~ * {
                  ${$isSideBarOpen
                    ? css`
                        padding-left: calc(${smallWidth} + ${sideMargin});
                      `
                    : css`
                        padding-left: calc(
                          ${closedHeaderWidth} + ${sideMargin}
                        );
                      `}

                  ${breakpoint.m} {
                    ${$isSideBarOpen
                      ? css`
                          padding-left: calc(${mediumWidth} + ${sideMargin});
                        `
                      : css`
                          padding-left: calc(
                            ${closedHeaderWidth} + ${sideMargin}
                          );
                        `}
                  }
                }
              }
            `
          : css`
              right: 0;
              border-left: 2px solid ${colors.border};
              && {
                ~ * {
                  ${$isSideBarOpen
                    ? css`
                        padding-right: calc(${smallWidth} + ${sideMargin});
                      `
                    : css`
                        padding-right: calc(
                          ${closedHeaderWidth} + ${sideMargin}
                        );
                      `}
                  ${breakpoint.m} {
                    ${$isSideBarOpen
                      ? css`
                          padding-right: calc(${mediumWidth} + ${sideMargin});
                        `
                      : css`
                          padding-right: calc(
                            ${closedHeaderWidth} + ${sideMargin}
                          );
                        `}
                  }
                }
              }
            `}
        height: 100%;

        ${breakpoint.m} {
          width: ${mediumWidth};
        }
      `;
    }
    return css`
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      + * {
        padding-top: ${HEADER_HEIGHT};
      }
      border-bottom: 2px solid ${colors.border};
      > div {
        > * {
          height: ${HEADER_HEIGHT};
          display: flex;
          align-items: center;
        }
      }
    `;
  }}

  background-color: var(--headerBgColor);
  color: var(--headerTextColor);
  z-index: ${zIndex.Header};
`;

const BurgerButton = styled.button`
  all: unset;
  cursor: pointer;

  position: relative;

  display: flex;
  flex-direction: column;
  gap: 3px;
  margin: 0.5rem;
  padding: 0.5rem;
  > div {
    width: 1.25rem;
    height: 2px;
    border-radius: 5px;
    background: var(--accent);
  }
`;

const HeaderContainer = styled(Layout)<{
  fluidHeader?: boolean;
  $navigationBarPosition: string;
}>`
  ${({ $navigationBarPosition, fluidHeader }) => {
    if (["left", "right"].includes($navigationBarPosition)) {
      return css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        padding: 1rem;
        gap: 2rem;

        ${breakpoint.m} {
          align-items: center;
        }
      `;
    }
    return css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: ${HEADER_HEIGHT};
      ${breakpoint.xs} {
        max-width: ${fluidHeader ? "none" : "93.75rem;"};
      }
    `;
  }}
`;

const HeaderItems = styled.nav<{
  fluidHeader?: boolean;
  $navigationBarPosition: string;
}>`
  ${({ $navigationBarPosition, fluidHeader }) => {
    if (["left", "right"].includes($navigationBarPosition)) {
      return css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
      `;
    }
    return css`
      display: flex;
      align-items: center;
      justify-content: end;
      margin-left: ${fluidHeader ? "2.3rem" : "0"};
    `;
  }}
`;

const LogoImg = styled.img`
  height: 24px;
  cursor: pointer;
`;

const Burger = ({ onClick }: { onClick: () => void }) => {
  return (
    <BurgerButton theme="blank" onClick={onClick}>
      <div />
      <div />
      <div />
    </BurgerButton>
  );
};

interface Props {
  fluidHeader: boolean;
}

const navigationBarPosition = "top";
const HeaderComponent = forwardRef<HTMLElement, Props>(
  ({ fluidHeader = false }, ref) => {
    const { address } = useAccount();
    const [isOpen, setOpen] = useState(false);
    const { isLteM } = useBreakpoints();
    const burgerMenuBreakpoint = isLteM;

    const toggleMenu = () => {
      setOpen(!isOpen);
    };

    const Connect = useCallback(
      (props: Parameters<typeof ConnectButton>[0]) => {
        return (
          <>
            <ConnectButton {...props} showAddress={!address} />
          </>
        );
      },
      [address]
    );

    return (
      <Header
        $navigationBarPosition={navigationBarPosition}
        $isSideBarOpen={isOpen}
        ref={ref}
      >
        <HeaderContainer
          fluidHeader={fluidHeader}
          $navigationBarPosition={navigationBarPosition}
        >
          <>
            <Grid flexDirection="row" alignItems="center" $width="initial">
              <Link to={BosonRoutes.Root}>
                <LogoImg src={logo} alt="logo image" data-testid="logo" />
              </Link>
            </Grid>
            <HeaderItems
              fluidHeader={fluidHeader}
              $navigationBarPosition={navigationBarPosition}
            >
              {burgerMenuBreakpoint && (
                <>
                  <Connect />
                  <Burger onClick={toggleMenu} />
                </>
              )}
              {!burgerMenuBreakpoint && (
                <Connect navigationBarPosition={navigationBarPosition} />
              )}
            </HeaderItems>
          </>
        </HeaderContainer>
      </Header>
    );
  }
);

export default HeaderComponent;
