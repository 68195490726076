/*
    TODO: reorganize colors
  */
export const colors = {
  navy: "#222539",
  green: "#02F3A2",
  bosonSkyBlue: "#51BEFA",
  grey: "grey",
  arsenic: "#3a364f",
  darkOrange: "darkorange",
  darkRed: "darkred",
  cyan: "#00FFFF",
  grey2: "#D3D5DB",
  grey3: "#A1A1A1",
  froly: "#F46A6A",
  lightArrowColor: "#dedfe3",

  // Main
  primary: "#02F3A2",
  secondary: "#7829F9",
  // Second
  torquise: "#06F7D5",
  orange: "#FC6838",
  red: "#FC386A",
  blue: "#0299EE",
  lime: "#B6F952",
  // Basic
  black: "#09182C",
  darkGrey: "#556072",
  darkGreyTimeStamp: "#E8EAF1",
  lightGrey: "#F1F3F9",
  border: "#5560720f",
  white: "#FFFFFF",
  primaryBgColor: "#FFFFFF"
} as const;
