import { useMutation } from "react-query";

import { useApigee } from "../../../../components/apigee/context";
import { MetaSeller } from "../../../types/meta-seller";
import { fetchMetaSeller } from "./utils/fetchMetaSeller";
import { useCredentials } from "./utils/useCredentials";

type Variables = {
  seller: Partial<Omit<MetaSeller, "id">> & Pick<MetaSeller, "id">;
  optInToSellerUpdate: {
    functionName: string;
    functionSignature: string;
    r: string;
    s: string;
    v: string;
    nonce: string;
  };
  signerAddress: string;
};

export function useUpdateSeller(
  options: Parameters<typeof useMutation>[2] = {}
) {
  const apigeeApps = useApigee();
  const { sendHeaders } = useCredentials();
  return useMutation<
    { updateSellerTxHash: string; optInToSellerUpdateTxHash: string },
    unknown,
    Variables
  >(
    ["update-seller"],
    async (variables) => {
      const apigeeApp = apigeeApps.find(
        (apigeeApp) => apigeeApp.id === variables.seller.authId
      );
      return await fetchMetaSeller(
        `/seller/${variables.seller.id}?apikey=${apigeeApp?.credentials[0].consumerKey}`,
        {
          method: "PUT",
          headers: {
            ...(sendHeaders && { "x-developer-app-id": apigeeApp?.id || "" })
          },
          data: {
            ...variables
          }
        }
      );
    },
    {
      ...options
    }
  );
}
